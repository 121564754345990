import React, { useState } from 'react';
import landing from '../home-icon.png';
import letter from '../love-letter-icon.png';
import galleryImg from '../gallery-icon.png';
import datesImg from '../dates-active.png';
import { Box, Button, TextField } from '@mui/material';

function DateIeas({ dateIdeas, setDateIdeas, newDates, setNewDates, deleteDate }) {
    const [dateIdea, setDateIdea] = useState('');
    const [dateDescription, setDateDescription] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedDesc, setSelectedDesc] = useState('');
    const [generated, setGenerated] = useState(null);
    const [visited, setVisited] = useState([]);

    const getRandomItem = () => {
        const dates = newDates.concat(dateIdeas);
        var index;
        if (visited.length === dates.length) {
            index = Math.floor(Math.random() * dates.length);
            setVisited([index]);
        } else {
            // get a random index and see if it has already been visited
            do {
                index = Math.floor(Math.random() * dates.length);
            } while (visited.find(x => x === index) !== undefined) 

            // set the date idea and add the date to the visited array
            setVisited([...visited, index]);
        }
        setGenerated(dates[index].title);
    };

    const handleAddPress = () => {
        if (dateIdea === '') {
            alert('Cannot submit an empty date idea!')
            return;
        }
        if (newDates.find(idea => idea.title === dateIdea) !== undefined) {
            alert(`${dateIdea} already added`);
            return;
        }
        // if (dateDescription === '') {
        //     alert('Give your date idea a description!')
        // }
        setNewDates([...newDates, { title: dateIdea, description: dateDescription }]);
        setDateIdea('');
    };

    const handleSelect = (e) => {
        setSelectedDate(e.target.value);
    }

    const handleSelectAddPress = () => {
        if (selectedDate === '') {
            alert('Cannot submit an empty date idea!')
            return;
        }
        if (newDates.find(idea => idea.title === selectedDate) !== undefined) {
            alert(`${selectedDate} already added`);
            return;
        }
        setNewDates([...newDates, { title: selectedDate, description: selectedDesc }]);
    };

    const handleChange = (e) => {
        e.preventDefault();
        setDateIdea(e.target.value);
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddPress();
        }
    }

    const handleDeleteNew = (index) => {
        console.log(index);
        setNewDates(prev => prev.slice(0, index).concat(prev.slice(index+1)));
    };

    return (
        <>
        <Box component={'form'} style={{ height: '100%', width: '35%', backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBlock: 45 }}>
                <h3>Date Ideas:</h3>
                <TextField value={dateIdea} name="date" style={{ width: '100%' }} onChange={handleChange} onKeyDown={handleEnter}/>
                <Button variant='contained' onClick={handleAddPress}>Add Date</Button>
                <select value={selectedDate} onChange={handleSelect} style={{ padding: 20, width: '100%', marginTop: 20}}>
                    <option value={''}>Choose from preset dates</option>
                    <option value={'Wine tasting'}>Wine tasting</option>
                    <option value={'Thrift shopping'}>Thrift shopping</option>
                    <option value={'Picnic'}>Picnic</option>
                    <option value={'Hiking'}>Hiking</option>
                    <option value={'Dance class'}>Dance class</option>
                </select>
                <Button variant='contained' onClick={handleSelectAddPress}>Add Date</Button>
            </div>
            <div style={{ width: '70%', borderTopColor: 'lightgrey', borderTopWidth: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <h3>Dates:</h3>
                <div style={{ width: '100%', height: 200, overflowY: 'auto', textAlign: 'start' }}>
                    {dateIdeas.map((date, index) => <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <p>{date.title}</p>
                        <Button variant='text' onClick={() => deleteDate(date.id)}>X</Button>
                    </div>)}
                    {newDates.map((date, index) => <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <p>{date.title}</p>
                        <Button variant='text' onClick={() => handleDeleteNew(index)}>X</Button>
                    </div>)}
                </div>
            </div>
        </Box>
        <div style={{ height: '100%', width: '65%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ backgroundColor: '#FFEDFA', width: 325, height: 550, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '90%', width: '100%', justifyContent: 'center', alignItems: 'center'  }}>
                    {generated ? <h2>{generated}</h2> : null}
                    <Button onClick={getRandomItem} variant="contained" style={{ backgroundColor: 'navy', fontSize: 10, padding: 15, borderRadius: 15, width: '50%' }}>Generate Date Idea</Button>
                </div>
                <div style={{ backgroundColor: '#F8CFFF', width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={landing} alt="landing icon"style={{ width: 15, height: 15 }}/>
                        <p style={{ fontSize: 8, color: 'white', margin: 0}}>Home</p>
                    </div>
                    <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={letter} alt="love letter icon" style={{ width: 15, height: 15 }}/>
                        <p style={{ fontSize: 8, color: 'white', margin: 0}}>Love Letter</p>
                    </div>
                    <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={galleryImg} alt="gallery icon" style={{ width: 15, height: 15 }}/>
                        <p style={{ fontSize: 8, color: 'white', margin: 0}}>Gallery</p>
                    </div>
                    <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={datesImg} alt="date ideas icon" style={{ width: 15, height: 15 }}/>
                        <p style={{ fontSize: 8, color: '#FF80DB', margin: 0}}>Dates</p>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default DateIeas;