/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      name
      email
      loveLetter
      landingPageImage {
        url
        id
        createdAt
        updatedAt
        userGalleryId
      }
      gallery {
        nextToken
      }
      dateIdeas {
        nextToken
      }
      id
      createdAt
      updatedAt
      userLandingPageImageId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        email
        loveLetter
        id
        createdAt
        updatedAt
        userLandingPageImageId
      }
      nextToken
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      url
      id
      createdAt
      updatedAt
      userGalleryId
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        url
        id
        createdAt
        updatedAt
        userGalleryId
      }
      nextToken
    }
  }
`;
export const getDate = /* GraphQL */ `
  query GetDate($id: ID!) {
    getDate(id: $id) {
      title
      description
      id
      createdAt
      updatedAt
      userDateIdeasId
    }
  }
`;
export const listDates = /* GraphQL */ `
  query ListDates(
    $filter: ModelDateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        title
        description
        id
        createdAt
        updatedAt
        userDateIdeasId
      }
      nextToken
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        email
        loveLetter
        gallery {
          items {
            url
            id
          }
        }
        dateIdeas {
          items {
            title
            description
            id
          }
        }
        id
        createdAt
        updatedAt
        userLandingPageImageId
      }
      nextToken
    }
  }
`;
