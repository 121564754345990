/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:fc655fca-3ad8-466e-929a-c8ef83eece86",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FWTO55gfa",
    "aws_user_pools_web_client_id": "4a358g42s864b98u8jf0e1977p",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://fswg4kfx4jdtnpb53jgaaxmwha.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5nry2u2gmjhlppd32lv7d43yd4",
    "aws_user_files_s3_bucket": "weddinggift564faebbbe8945f18d56705271fd7068182000-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
