import { Box, Button, TextField } from '@mui/material';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import logo from '../sign-in-icon.png';

function SignInScreen(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        let user = await Auth.signIn(email, password);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            Auth.completeNewPassword(user, password);
        }
    }
    return (
        <Box component={'form'} onSubmit={handleSubmit} style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'aliceblue'}}>
            <img src={logo} style={{ marginBottom: 50 }} alt="logo" />
            <TextField variant='standard' placeholder='Email' value={email} onChange={e=>setEmail(e.target.value)} style={{ width: '25%', padding: 10 }} required/>
            <TextField variant='standard' type={'password'} placeholder='Password' value={password} onChange={e=>setPassword(e.target.value)} style={{ width: '25%', padding: 10 }} required/>
            <Button type="submit" variant='contained' style={{ padding: 10, width: '25%', backgroundColor: 'navy', borderRadius: 15 }}>Sign in</Button>
        </Box>
    );
}

export default SignInScreen;