import { Box, TextField } from '@mui/material';
import React from 'react';
import landing from '../home-icon.png';
import letterImg from '../love-letter-active.png';
import gallery from '../gallery-icon.png';
import dates from '../dates-icon.png';

function LoveLetter({ letter, setLetter }) {

    const handleLetterChange = (e) => {
        setLetter(e.target.value);
    };

    return (
        <>
            <Box component={'form'} style={{ height: '100%', width: '35%', backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBlock: 45 }}>
                    <h3>Love Letter:</h3>
                    <TextField value={letter} onChange={handleLetterChange} style={{ width: '100%' }} multiline rows={16} />
                </div>
            </Box>
            <div style={{ height: '100%', width: '65%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ backgroundColor: '#FFEDFA', width: 325, height: 550, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '80%', height: '80%', padding: 10, backgroundColor: 'white', marginTop: '5%', overflowY: 'auto' }}>
                        {letter ? <p style={{ textAlign: 'start', fontSize: 10, whiteSpace: 'pre-line' }}>{letter}</p> : <p style={{ textAlign: 'start', fontSize: 10 }}>Love letter starts here...</p>}
                    </div>
                    <div style={{ backgroundColor: '#F8CFFF', width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={landing} alt="landing icon"style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Home</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={letterImg} alt="love letter icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: '#FF80DB', margin: 0}}>Love Letter</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={gallery} alt="gallery icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Gallery</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={dates} alt="date ideas icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Dates</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoveLetter;