/* 
This is the dashboard that customers will use to upload the info for the app
we will need to capture:
1. User's name
2. A photo for the landing page
3. A love letter (text input)
4. Images for the gallery
5. Date ideas (Free form input with dropdown selections to help) - requires title, description, date, start time, location, & cost

This dashboard will include:
1. tab for each page in the app
2. appopriate input fields for all the data captures above
3. a "demo" screen that shows the layout of the app with their input

 */

import { Button } from '@mui/material';
import { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import DateIeas from '../components/DateIeas';
import Gallery from '../components/Gallery';
import LandingPage from '../components/LandingPage';
import LoveLetter from '../components/LoveLetter';
import { createDate, createPhoto, createUser, deleteDate, deletePhoto, updatePhoto, updateUser } from '../graphql/mutations';
import { userByEmail } from '../graphql/queries';
import logo from '../sign-in-icon.png';

function Dashboard({ user }) {
    // tab navigation variable
    const [render, setRender] = useState('landing');

    // variables for mobile app
    const [userId, setUserId] = useState(null);
    const [name, setName] = useState('');
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);
    const [letter, setLetter] = useState('');
    const [gallery, setGallery] = useState([]);
    const [dateIdeas, setDateIdeas] = useState([]);
    const [userExists, setUserExists] = useState(false);
    const [image, setImage] = useState(null);
    const [images, setImages] = useState([]);
    
    // delete variables when updating
    const [imagesTrash, setImagesTrash] = useState([]);
    const [datesTrash, setDatesTrash] = useState([]);
    
    // new images & dates when updating
    const [newDates, setNewDates] = useState([]);
    const [galleryFiles, setGalleryFiles] = useState([]);
    
    // helper variables
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            // Check for an existing user in the dB
            const userDb = await API.graphql(graphqlOperation(userByEmail, { email: user.attributes.email }));
            console.log(userDb.data.userByEmail.items);
            // if there is one, then populate all the data on the User object
            if (userDb.data.userByEmail.items.length > 0) {
                setUserExists(true);
                setUserId(userDb.data.userByEmail.items[0].id);
                setName(userDb.data.userByEmail.items[0].name);
                setFileName(userDb.data.userByEmail.items[0].userLandingPageImageId);
                setLetter(userDb.data.userByEmail.items[0].loveLetter);
                setGallery(userDb.data.userByEmail.items[0].gallery.items);
                setDateIdeas(userDb.data.userByEmail.items[0].dateIdeas.items);
            }
        })();
    }, []);

    const handleUserCreation = async () => {
        setLoading(true);
        try {
            // check to ensure all mobile app variables are filled in
            if (name === '') throw 'Name is required on the Landing Page!'
            if (!file) throw 'A photo is required for the Landing Page!'
            if (letter === '') throw 'Your love letter is required on the Love Letter Page!'
            if (galleryFiles.length < 1) throw 'You need to upload at least one image to the Gallery!'
            if (newDates.length < 1) throw 'You have to at least have one good date idea for the Date Idea Page!'

            // create the photos in the dB & S3
            const selectedFileName = file.name.split(" ").join("_");
            const landingPhoto = await API.graphql(graphqlOperation(createPhoto, { input: { url: selectedFileName }}))
            const storageEvent = await Storage.put(landingPhoto.data.createPhoto.id, file);

            // add the user to the dB
            const userInput = {
                name,
                email: user.attributes.email,
                loveLetter: letter,
                userLandingPageImageId: storageEvent.key,
            }  
            const newUser = await API.graphql(graphqlOperation(createUser, { input: userInput }));

            // create all the images to the gallery in the dB
            (async () => {
                for (let i = 0; i < galleryFiles.length; i++) {
                    const galleryImageName = galleryFiles[i].name.split(" ").join("_");
                    const photoCreation = await API.graphql(graphqlOperation(createPhoto, { input: { url: galleryImageName, userGalleryId: newUser.data.createUser.id }}));
                    console.log(photoCreation);
    
                    const imageStorage = await Storage.put(photoCreation.data.createPhoto.id, galleryFiles[i]);
                    console.log(imageStorage);
                }
            })();

            newDates.forEach(date => {
                (async () => {
                    const dateInput = {
                        title: date.title,
                        description: '',
                        userDateIdeasId: newUser.data.createUser.id 
                    }
                    await API.graphql(graphqlOperation(createDate, { input: dateInput }));
                })();
            })

            setUserExists(true);
            setLoading(false);
        } catch (error) {
            alert(error);
            setLoading(false);
        }
    };

    const handleUserUpdate = async () => {
        setLoading(true);
        console.log({newDates, galleryFiles});
        try {
            // check to ensure all mobile app variables are filled in
            if (name === '') throw 'Name is required on the Landing Page!'
            if (letter === '') throw 'Your love letter is required on the Love Letter Page!'
            if (gallery.length+galleryFiles.length < 1) throw 'You need to upload at least one image to the Gallery!'
            if (dateIdeas.length+newDates.length < 1) throw 'You have to at least have one good date idea for the Date Idea Page!'

            // create the photos in the dB & S3
            if (file) {
                console.log(fileName);
                await API.graphql(graphqlOperation(deletePhoto, { input: { id: fileName }}))
                await Storage.remove(fileName);

                const selectedFileName = file.name.split(" ").join("_");
                const landingPhoto = await API.graphql(graphqlOperation(createPhoto, { input: { url: selectedFileName }}))
                await Storage.put(landingPhoto.data.createPhoto.id, file);
                
                // update the user with new landing page image
                const userInput = {
                    id: userId,
                    name,
                    loveLetter: letter,
                    userLandingPageImageId: landingPhoto.data.createPhoto.id
                }
                await API.graphql(graphqlOperation(updateUser, { input: userInput }));
            } else {
                // update the user w/o new landing page image 
                const userInput = {
                    id: userId,
                    name,
                    loveLetter: letter,
                }
                await API.graphql(graphqlOperation(updateUser, { input: userInput }));
            }
            
            // delete all the images & dates from the trash
            imagesTrash.forEach(piece => {
                (async () => {
                    await API.graphql(graphqlOperation(deletePhoto, { input: { id: piece }}));
                    await Storage.remove(piece);
                })();
            });

            datesTrash.forEach(piece => {
                (async () => {
                    await API.graphql(graphqlOperation(deleteDate, { input: { id: piece }}));
                })();
            });

            // create all the new images & dates in the dB
            galleryFiles.forEach(async image => {
                const galleryImageName = image.name.split(" ").join("_");
                const photoRes = await API.graphql(graphqlOperation(createPhoto, { input: { url: galleryImageName, userGalleryId: userId }}));
                const storageEvent = await Storage.put(photoRes.data.createPhoto.id, image);
                console.log(storageEvent);
            })

            newDates.forEach(date => {
                (async () => {
                    const dateInput = {
                        title: date.title,
                        description: '',
                        userDateIdeasId: userId 
                    }
                    await API.graphql(graphqlOperation(createDate, { input: dateInput }));
                })();
            })

            setLoading(false);
        } catch (error) {
            alert(error);
            setLoading(false);
        }
    };

    const handleDeleteImage = (id) => {
        setImagesTrash([...imagesTrash, id]);
        setGallery(prev => prev.filter(item => item.id !== id));
    };

    const handleDeleteDate = (id) => {
        setDatesTrash([...datesTrash, id]);
        setDateIdeas(prev => prev.filter(item => item.id !== id));
    };

    return (
        <div style={{ width: '100vw', height: '100vh', backgroundColor: 'aliceblue', display: 'flex', flexDirection: 'column' }}>
            <div style={{ height: '15%', width: '100%', display: 'flex', flexDirection: 'row', backgroundColor: '#f7fcf7', boxShadow: '1px 1px 10px lightblue', zIndex: 2, justifyContent: 'space-between' }}>
                <img src={logo} alt="logo" style={{ width: 100, height: 80, alignSelf: 'center', paddingLeft: 20 }}/>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                    {render !== 'landing' ? <Button style={tab1} onClick={() => setRender('landing')}>Landing Page</Button> : <Button style={{ width: '25%', borderBottom: '2px solid navy', borderRadius: 0 }} onClick={() => setRender('landing')}>Landing Page</Button>}
                    {render !== 'letter' ? <Button style={tab1} onClick={() => setRender('letter')}>Love Letter</Button> : <Button style={{ width: '25%', borderBottom: '2px solid navy', borderRadius: 0 }} onClick={() => setRender('letter')}>Love Letter</Button>}
                    {render !== 'gallery' ? <Button style={tab1} onClick={() => setRender('gallery')}>Gallery</Button> : <Button style={{ width: '25%', borderBottom: '2px solid navy', borderRadius: 0 }} onClick={() => setRender('gallery')}>Gallery</Button>}
                    {render !== 'dates' ? <Button style={tab1} onClick={() => setRender('dates')}>Date Ideas</Button> : <Button style={{ width: '25%', borderBottom: '2px solid navy', borderRadius: 0 }} onClick={() => setRender('dates')}>Date Ideas</Button>}
                    <Button style={tab1} onClick={() => Auth.signOut()}>Sign out</Button>
                </div>
            </div>
            <div style={{ height: '85%', width: '100%', display: 'flex', flexDirection: 'row' }}>
                {
                render === 'landing' ? <LandingPage name={name} setName={setName} fileName={fileName} setFile={setFile} image={image} setImage={setImage}/> :
                render === 'letter' ? <LoveLetter letter={letter} setLetter={setLetter} /> :
                render === 'gallery' ? <Gallery gallery={gallery} setGallery={setGallery} galleryFiles={galleryFiles} setGalleryFiles={setGalleryFiles} images={images} setImages={setImages} deleteImage={handleDeleteImage} /> :
                <DateIeas dateIdeas={dateIdeas} setDateIdeas={setDateIdeas} newDates={newDates} setNewDates={setNewDates} deleteDate={handleDeleteDate} />
                }
                <Button variant='contained' onClick={!userExists ? handleUserCreation : handleUserUpdate} style={{ position: 'absolute', bottom: 15, right: 15, zIndex: 2}} disabled={loading ? true : false}>{!loading ? 'Publish App' : 'Publishing...'}</Button>
            </div>
        </div>
    );
}

const tab1 = {
    width: '25%',
}
const tab2 = {
    width: '25%',
}

export default Dashboard;