import { Box, TextField } from '@mui/material';
import React, { useState } from 'react';
import landing from '../home-active.png';
import letter from '../love-letter-icon.png';
import gallery from '../gallery-icon.png';
import dates from '../dates-icon.png';

const baseUrl = 'https://weddinggift564faebbbe8945f18d56705271fd7068182000-staging.s3.amazonaws.com/public/';

function LandingPage({ name, setName, fileName, setFile, image, setImage }) {

    const handleChange = (event) => {
        setFile(event.target.files[0]);
        setImage(URL.createObjectURL(event.target.files[0]));
    };

    return (
        <>
            <Box component={'form'} style={{ height: '100%', width: '35%', backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBlock: 45 }}>
                    <h3>Name:</h3>
                    <TextField value={name} onChange={e=>setName(e.target.value)} style={{ width: '100%' }}/>
                </div>
                <div style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 50}}>
                    <h3>Landing page image:</h3>
                    <TextField type={'file'} name="icon" style={{ width: '100%' }} onChange={handleChange} />
                </div>
            </Box>
            <div style={{ height: '100%', width: '65%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ backgroundColor: '#FFEDFA', width: 325, height: 550, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    {image ? <img src={image} alt="uploaded image" style={{ width: '100%', height: '40%', backgroundSize: 'cover' }} /> : fileName !== '' ? <img src={baseUrl+fileName} alt="uploaded image" style={{ width: '100%', height: '40%', backgroundSize: 'cover' }} /> : <div style={{ width: '100%', height: '40%', backgroundColor: 'lightgrey' }}></div>}
                    {name !== '' ? <p style={{ padding: 5, marginInline: 5, marginBlock: 0 }}>Dear {name},</p> : <p style={{ padding: 5, marginInline: 5, marginBlock: 0 }}>Dear _________,</p>}
                    <p style={{ textAlign: 'left', padding: 5, marginInline: 5, marginBottom: 25 }}>{'\n'}Happy wedding day! My love is so great for you that I couldn't just express it in words, so I created this app! Check out the various tabs to see just how much you mean to me.</p>
                    <div style={{ backgroundColor: '#F8CFFF', width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={landing} alt="landing icon"style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: '#FF80DB', margin: 0}}>Home</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={letter} alt="love letter icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Love Letter</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={gallery} alt="gallery icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Gallery</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={dates} alt="date ideas icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Dates</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LandingPage;