import React from 'react';
import landing from '../home-icon.png';
import letter from '../love-letter-icon.png';
import galleryImg from '../gallery-active.png';
import dates from '../dates-icon.png';
import { Box, Button, TextField } from '@mui/material';
import { Masonry } from '@mui/lab';

const baseUrl = 'https://weddinggift564faebbbe8945f18d56705271fd7068182000-staging.s3.amazonaws.com/public/';

function Gallery({ gallery, setGallery, galleryFiles, setGalleryFiles, images, setImages, deleteImage }) {
    const handleChange = (e) => {
        setGalleryFiles([...galleryFiles, e.target.files[0]]);
        setImages([...images, URL.createObjectURL(e.target.files[0])]);
    };

    const handleDeleteNew = (index) => {
        console.log(index);
        setGalleryFiles(prev => prev.slice(0, index).concat(prev.slice(index+1)));
        setImages(prev => prev.slice(0, index).concat(prev.slice(index+1)));
    };

    return (
        <>
            <Box component={'form'} style={{ height: '100%', width: '35%', backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBlock: 45 }}>
                    <h3>Gallery Images:</h3>
                    <TextField type={'file'} name="icon" style={{ width: '100%' }} onChange={handleChange} />
                    {gallery.map((img, index) => <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <p>{img.url}</p>
                        <Button variant='text' onClick={() => deleteImage(img.id)}>X</Button>
                    </div>)}
                    {galleryFiles.map((img, index) => <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <p>{img.name}</p>
                        <Button variant='text' onClick={() => handleDeleteNew(index)}>X</Button>
                    </div>)}
                </div>
            </Box>
            <div style={{ height: '100%', width: '65%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ backgroundColor: '#FFEDFA', width: 325, height: 550, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        {gallery.length > 0 || images.length > 0 ? <Masonry columns={3} spacing={2}>
                            {gallery.map((item, index) => (
                                <div key={index}>
                                    <img
                                    src={baseUrl+item.id}
                                    alt={`gallery img ${index}`}
                                    loading="lazy"
                                    style={{
                                        borderBottomLeftRadius: 4,
                                        borderBottomRightRadius: 4,
                                        display: 'block',
                                        width: '100%',
                                    }}
                                    />
                                </div>
                            ))}
                            {images.map((item, index) => (
                                <div key={index}>
                                    <img
                                    src={item}
                                    alt={`gallery img ${index}`}
                                    loading="lazy"
                                    style={{
                                        borderBottomLeftRadius: 4,
                                        borderBottomRightRadius: 4,
                                        display: 'block',
                                        width: '100%',
                                    }}
                                    />
                                </div>
                            ))}
                        </Masonry> : null}
                    </div>
                    <div style={{ backgroundColor: '#F8CFFF', width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={landing} alt="landing icon"style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Home</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={letter} alt="love letter icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Love Letter</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={galleryImg} alt="gallery icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: '#FF80DB', margin: 0}}>Gallery</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={dates} alt="date ideas icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Dates</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Gallery;