/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      name
      email
      loveLetter
      landingPageImage {
        url
        id
        createdAt
        updatedAt
        userGalleryId
      }
      gallery {
        nextToken
      }
      dateIdeas {
        nextToken
      }
      id
      createdAt
      updatedAt
      userLandingPageImageId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      name
      email
      loveLetter
      landingPageImage {
        url
        id
        createdAt
        updatedAt
        userGalleryId
      }
      gallery {
        nextToken
      }
      dateIdeas {
        nextToken
      }
      id
      createdAt
      updatedAt
      userLandingPageImageId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      name
      email
      loveLetter
      landingPageImage {
        url
        id
        createdAt
        updatedAt
        userGalleryId
      }
      gallery {
        nextToken
      }
      dateIdeas {
        nextToken
      }
      id
      createdAt
      updatedAt
      userLandingPageImageId
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      url
      id
      createdAt
      updatedAt
      userGalleryId
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      url
      id
      createdAt
      updatedAt
      userGalleryId
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      url
      id
      createdAt
      updatedAt
      userGalleryId
    }
  }
`;
export const createDate = /* GraphQL */ `
  mutation CreateDate(
    $input: CreateDateInput!
    $condition: ModelDateConditionInput
  ) {
    createDate(input: $input, condition: $condition) {
      title
      description
      id
      createdAt
      updatedAt
      userDateIdeasId
    }
  }
`;
export const updateDate = /* GraphQL */ `
  mutation UpdateDate(
    $input: UpdateDateInput!
    $condition: ModelDateConditionInput
  ) {
    updateDate(input: $input, condition: $condition) {
      title
      description
      id
      createdAt
      updatedAt
      userDateIdeasId
    }
  }
`;
export const deleteDate = /* GraphQL */ `
  mutation DeleteDate(
    $input: DeleteDateInput!
    $condition: ModelDateConditionInput
  ) {
    deleteDate(input: $input, condition: $condition) {
      title
      description
      id
      createdAt
      updatedAt
      userDateIdeasId
    }
  }
`;
